import 'vue-readable-console-log';
import '@/scss/global.scss';

import { useSvgThemeLoader, useUITexts } from '@/plugins';
import { useHasSlotContent } from '@bam/js';

import App from "@/App.vue";
import VueAxios from "vue-axios";
import axios from "axios";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { createLoginRouter } from "@/router";

const pinia = createPinia();
const app = createApp(App);
const loginRouter = createLoginRouter({ pinia, app });

app
    .use(pinia)
    .use(loginRouter)
    .use(useSvgThemeLoader)
    .use(useUITexts)
    .use(useHasSlotContent)
    .use(VueAxios, { $axios: axios })
    .mount("#app");
