import { createRouter, createWebHistory } from 'vue-router';

import { useLoginPageStore } from '@/store';

import {
    guardChannelType,
    guardSingleType,
    guardStructureType,
    guardNProgessDone,
    guardNProgessStart,
    guardNavigationStore,
    guardUiTextsStore,
} from '@/router/guards';

import set from 'set-value';

import { setupLayouts } from 'virtual:generated-layouts';

export const createLoginRouter = ({ pinia, app }) => {
    const routes = [
        {
            name: 'loginHome',
            path: '/:uri(.*)*',
            component: () => import('@/pages/LoginHome.vue'),
            props: route => ({
                ...route.params,
                lang: 'loginDe',
            }),
            meta: {
                layout: 'login',
                sectionType: 'structure',
                store: useLoginPageStore(pinia),
            },
        },
        {
            name: 'loginSample',
            path: '/',
            alias: ['login.html','login'],
            component: () => import('@/pages/LoginHome.vue'),
            props: route => ({
                ...route.params,
                lang: 'loginDe',
            }),
            meta: {
                layout: 'login',
                sectionType: 'structure',
                store: useLoginPageStore(pinia),
            },
        },
    ];

    const layoutedRoutes = setupLayouts(routes);

    const basePath =
        process.env.NODE_ENV === 'production'
            ? import.meta.env.VITE_LOGIN_STAGING
                ? import.meta.env.VITE_LOGIN_BASE_STAGING
                : import.meta.env.VITE_LOGIN_BASE_PRODUCTION
            : null;

    const router = createRouter({
        history: createWebHistory(basePath),
        routes: layoutedRoutes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            }

            return { left: 0, top: 0 };
        },
    });

    router.beforeEach(guardNProgessStart);

    router.beforeEach(async (to, from) => {
        const { store = null, sectionType = null } = to.meta;

        if (to.path === from.path) { return false; }
        if (!store) { return; }

        store.authState = store.authState || to.query.AuthState || null;

        set(to, 'params.lang', 'loginDe');

        await guardNavigationStore(to, from);

        // Temporarily map old Altares webhooks to our internal entry slugs
        let targetUri = Array.isArray(to.params.uri) && to.params.uri.length > 0 ? to.params.uri[0] : to.params.uri;
        switch (targetUri) {
            case '':
            case 'loginuserpass.php': targetUri = ''; break; // Get Authstate from URL

            case 'pwchange.php': targetUri = 'passwort-aendern'; break;
            case 'pwchangeconfirm.php': targetUri = 'passwort-aendern-abschlussmeldung'; break;

            case 'pwforgotten.php': targetUri = 'passwort-vergessen'; break;
            case 'pwforgottenconfirm.php': targetUri = 'passwort-vergessen-abschlussmeldung'; break;

            case 'registration.php': targetUri = 'registrierung'; break;
            case 'registrationconfirm.php': targetUri = 'registrierung-abschlussmeldung'; break;
        }
        set(to, 'params.uri', targetUri);

        switch (sectionType) {
            case 'single':
                return await guardSingleType(store, to, from, false);

            case 'channel':
                return await guardChannelType(store, to, from, false);

            case 'structure':
                return await guardStructureType(store, to, from, false);

            default:
                break;
        }
    });

    router.beforeEach(guardUiTextsStore);
    router.afterEach(guardNProgessDone);

    return router;
};
